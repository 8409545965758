<script>
import api from '@/command/api'
import DetailFormGroup from '../../../../components/DetailFormGroup'
import { getAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'
import moment from 'moment'
export default {
  data() {
    return {
      ...api.command.getState(),
      detail: {},
      reallyDetail: {}
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail
        .call(this, {
          url: `/account/firm/info/${id}`
        })
        .then(result => {
          this.reallyDetail = result.sysUserDTO
        })
  },
  methods: {
    getForm1() {
      return {
        title: '用户信息',
        type: 'cardForm',
        gutter: 15,
        data: [
          {
            name: '昵称',
            type: 'input',
            cols: 12,
            key: 'nickName'
          },
          {
            name: '真实姓名',
            type: 'input',
            cols: 12,
            key: 'realName'
          },
          {
            name: '用户名',
            type: 'input',
            cols: 12,
            key: 'username'
          },
          {
            name: '性别',
            type: 'select',
            cols: 12,
            key: 'gender',
            typeData: [
              {
                name: '男',
                value: '男'
              },
              {
                name: '女',
                value: '女'
              },
              {
                name: '保密',
                value: '保密'
              }
            ]
          },
          {
            name: '手机号码',
            type: 'input',
            cols: 12,
            key: 'phone'
          },
          {
            name: '备注',
            type: 'input',
            cols: 12,
            key: 'remark'
          }
        ]
      }
    },

    getForm2() {
      return {
        type: 'cardForm',
        title: '用户设置',
        data: [
          {
            name: '密码安全级别',
            type: 'inputNumber',
            cols: 12,
            key: 'pwdSecurityLevel'
          },
          {
            name: '微信小程序openid',
            type: 'input',
            cols: 12,
            key: 'miniOpenid'
          },
          {
            name: '微信openid',
            type: 'input',
            cols: 12,
            key: 'wxOpenid'
          },
          {
            name: '友盟ID',
            type: 'input',
            cols: 12,
            key: 'ymId'
          },
          {
            name: '昵称',
            type: 'input',
            cols: 12,
            key: 'nickName'
          },

          {
            name: '头像',
            type: 'upload',
            maxLength: 1,
            cols: 12,
            key: 'avatar'
            // rules: [
            //   {
            //     required: true,
            //     message: '请上传图片',
            //     type: 'string'
            //   }
            // ]
          }
        ]
      }
    },

    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: data => {
            console.log(data)
            api.command[this.detail.userId ? 'edit' : 'create']
              .call(this, {
                url: '/app',
                params: {
                  ...data
                },
                isPost: false
              })
              .then(result => {
                this.$router.push('/rights/user/bodyMain')
              })
          }
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            this.$router.push('/rights/user/bodyMain')
          }
        }
      ]

      return {
        left
      }
    }
  },
  render() {
    return <DetailFormGroup foot={this.getFoot()} form={this.reallyDetail} data={[this.getForm1(), this.getForm2()]} />
  }
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
